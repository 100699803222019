import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Project from '../components/Project';

export const query = graphql`
    query($slug: String!) {
        contentfulProject(slug: {eq: $slug}) {
            slug
            tags
            title
            shortDescription
            images {
                id
                fluid (maxWidth: 3000,quality:100) {
                  ...GatsbyContentfulFluid
                }
              }
            
        }
    }
`

const ProjectTemplate = ({ data }) => {
    const project = data.contentfulProject;
    console.log('data', data)
    const tags = project.tags;
    const title = project.title;
    const description = project.shortDescription;
    const imageData = project.images;


    return (
        <Layout>
            <Project
                title={title}
                description={description}
                imageData={imageData}
                tags={tags}
            />
        </Layout>
    )
}

export default ProjectTemplate;